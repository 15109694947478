export function loadSpecificGalleries(galleries) {
  for (let i = 0; i < galleries.length; i++) {
    loadGallery(galleries[i]);
  }
}

export function loadChildGalleries(elementOrSelector) {
  const $element =
    elementOrSelector instanceof $ ? elementOrSelector : $(elementOrSelector);
  const galleries = ($element as ReturnType<typeof $>).find(
    ".gallery-container"
  );
  loadSpecificGalleries(galleries);
}

export function loadGallery(gallery) {
  let slideIndex = 0;
  const slides = gallery.getElementsByClassName("slide");
  const thumbnailWrappers = gallery.getElementsByClassName("thumbnail-cell");
  const slidesWrapper = gallery.getElementsByClassName("slides-wrapper");

  const nextBtn = gallery.getElementsByClassName("next")[0];
  const prevBtn = gallery.getElementsByClassName("prev")[0];

  const gallerySensitiveContent = gallery.querySelectorAll(
    ".sensitive-content a"
  );
  const galleryArray = Array.prototype.slice.call(gallerySensitiveContent);

  if (slidesWrapper && slidesWrapper.length > 0) {
    const $slidesWrapper = $(slidesWrapper[0]);
    $slidesWrapper.on("swipeleft", () => {
      showSlides(slideIndex + 1);
    });

    $slidesWrapper.on("swiperight", () => {
      showSlides(slideIndex - 1);
    });
  }

  nextBtn.addEventListener("click", () => {
    showSlides(slideIndex + 1);
  });
  prevBtn.addEventListener("click", () => {
    showSlides(slideIndex - 1);
  });

  galleryArray.forEach((dom) => {
    dom.addEventListener("click", () => {
      showNSFWThumbnail(slideIndex);
    });
  });

  for (let i = 0; i < thumbnailWrappers.length; i++) {
    thumbnailWrappers[i].addEventListener("click", (event) => {
      const currentIndex = $(event.currentTarget).data("index");
      showSlides(currentIndex);
    });
  }

  function showSlides(n) {
    if (n > slides.length - 1) {
      slideIndex = 0;
    } else if (n < 0) {
      slideIndex = slides.length - 1;
    } else {
      slideIndex = n;
    }

    // hide all slides and thumbnail
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
      thumbnailWrappers[i].className = thumbnailWrappers[i].className.replace(
        " active",
        ""
      );
    }

    if (!slides.length) return;

    // show active slide and thumbnail
    slides[slideIndex].style.display = "inline-block";
    thumbnailWrappers[slideIndex].className += " active";
  }

  function showNSFWThumbnail(index) {
    const thumbnailCell = gallery.querySelector(
      `.thumbnail-cell[data-index="${index}"]`
    );

    if (thumbnailCell) {
      thumbnailCell.querySelector(".sensitive-content").style.display = "none";
      thumbnailCell
        .querySelector(".sensitive-hidden")
        .classList.add("show-nsfw");
    }
  }

  showSlides(slideIndex);
}

const galleries = document.getElementsByClassName("gallery-container");
loadSpecificGalleries(galleries);
